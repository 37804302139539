<template>
  <div class="table-editable-cell-block"
       :class="[{'edit-mode': tableMode === 'edit'}, classes]">
    <div class="cell-title-block"
         :class="{'edit-mode': tableMode === 'edit'}">
      <div class="title">
        <slot name="cell-title"></slot>
      </div>
      <div class="character-counter"
           v-if="showLengthLimits"
           :class="{green: inputModel?.length === textMaxLength, red: inputModel?.length === 0}">
        {{ sanitizeText(inputModel)?.length ?? 0 }}/{{ textMaxLength }}
      </div>
    </div>

    <div class="table-editable-cell"
         :class="{view: tableMode === 'view'}">

      <template v-if="tableMode === 'view'">
        <div class="view-mode-cell"
             :class="{keywords: cellType === 'keyword-cell'}">

          <div class="input" v-if="inputModel">
            <span v-html="highlightDuplicatedWords(inputModel)"></span>
            <span class="used-words" v-if="usedWordsData?.length > 0">
              <template v-for="word in usedWordsData" >
                 ,
                <span class="used-word">
                  {{ word }}
                </span>
              </template>
            </span>
          </div>
          <div v-else class="no-title-block">
            <template v-if="viewModePlaceholder === 'No title'">
              <div>
                Words to use
                <div v-tooltip="{text: 'You can add words from the table below (see Words tab).', position: 'right', classes: [ 'words-to-use-tooltip']}">
                  <svg-icon icon="question-circle-solid"/>
                </div>
              </div>
            </template>
            <template v-else>
              {{ viewModePlaceholder }}
            </template>
          </div>
          <div class="store-data" v-if="storeData">
            <img src="@/assets/images/icons/app_store_gray.svg"
                 alt="Store icon"
                 class="store-icon"
                 width="18"
                 height="18"> {{ storeData }}
            <slot name="storeDataAfter"></slot>
          </div>
          <slot name="storeDataPlaceholder" v-else class="no-title-block"></slot>
        </div>
      </template>
      <template v-else>

        <textarea class="edit-mode-cell"
                v-model="inputModel"
                @input="inputChanged"
                @keydown.enter.prevent=""
                :maxlength="textMaxLength"
                :placeholder="editModePlaceholder ?? ''"></textarea>
        <div class="store-data" v-if="storeData">
          <img src="@/assets/images/icons/app_store_gray.svg"
               alt="Store icon"
               class="store-icon"
               width="18"
               height="18"> {{ storeData }}
        </div>

        <div class="edit-used-words">
          <div class="title">
            Used words
          </div>
          <div class="used-words" v-if="usedWordsData?.length > 0">
            {{ usedWordsData.join(', ') }}
          </div>
        </div>

      </template>
    </div>

    <div class="cell-options">
      <custom-tooltip :classes="'option-item edit-option'"
                      v-if="tableMode === 'view'"
                      :style="{minWidth: '30px'}"
                      direction-horizontal="left"
                      @click="turnOnEditMode">
        <template v-slot:slotTrigger>
          <svg-icon icon="pencil-icon"
                    class="icons-margin chart-icon"/>
        </template>
        <template v-slot:slotContent>
          Edit Meta
        </template>
      </custom-tooltip>

      <custom-tooltip :classes="'option-item copy-option'"
                      :style="{minWidth: '135px'}"
                      direction-horizontal="left"
                      @click="copyText">
        <template v-slot:slotTrigger>
          <svg-icon icon="copy"></svg-icon>
        </template>
        <template v-slot:slotContent>
          Copy to clipboard
        </template>
      </custom-tooltip>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TableCellTooltip from "@/views/MetaEditor/components/TableCellTooltip/index.vue";
import {splitTextToParts} from "@/utils/utils";

export default {
  name: "EditableTableCell",
  components: {
    SvgIcon,
    TableCellTooltip
  },
  emits: ['editable-data-changed', 'turn-on-edit-mode'],
  props: {
    tableMode: {
      type: String,
      default: 'view'
    },
    viewModePlaceholder: {
      type: String,
      default: 'No title'
    },
    editModePlaceholder: {
      type: String,
      default: 'Enter text'
    },
    storeData: {
      type: String,
    },
    initialData: {
      type: String,
    },
    initialListData: {
      type: Array,
    },
    duplicatedWords: {
      type: Array,
    },
    textMaxLength: {
      type: Number,
      default: 30,
    },
    cellType: {
      type: String,
      default: ''
    },
    showLengthLimits: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
    },
    noHighlight: {
      type: Boolean,
      default: false,
    },
    noInitialTrim: {
      type: Boolean,
      default: false,
    },
    usedWordsData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      inputModel: '',
      inputArray: [],
      showTooltip: false,
    }
  },
  mounted() {
    if (this.initialData !== '') {
      this.inputModel = this.noInitialTrim ? this.initialData : this.initialData?.substring(0, this.textMaxLength);
    }

    if (this.initialData === null && this.initialListData?.length > 0) {
      this.inputArray = [...this.initialListData];
      const nonEmptyItem = this.initialListData.filter(item => item !== '');
      this.inputModel = nonEmptyItem.join(',');
    }
  },
  methods: {
    inputChanged() {
      const isInputComplete = /[, ]$/.test(this.inputModel);

      if (this.initialData === null && this.initialListData?.length > 0 && isInputComplete) {
        const words = this.inputModel.split(/[, ]+/).map(word => word.trim())
        this.$emit('editable-data-changed', [...new Set(words)]);

        return;
      }

      this.$emit('editable-data-changed', this.inputModel);
    },
    sanitizeText(str) {
      return str?.replace(/(<([^>]+)>)/gi, "");
    },
    copyText() {
      const tempText = document.createElement("textarea");

      if (this.initialData !== '') {
        tempText.value = this.sanitizeText(this.initialData);
      }

      if (this.initialData === null && this.initialListData?.length > 0) {
        tempText.value = this.initialListData.join(',');
      }

      document.body.appendChild(tempText);
      tempText.select();

      document.execCommand("copy");
      document.body.removeChild(tempText);
    },
    highlightDuplicatedWords(inputString) {
      if (!inputString) {
        return inputString;
      }

      if (this.duplicatedWords?.length > 0) {
        let duplicatedWordsIndex = [];
        this.duplicatedWords.forEach((word) => {
          duplicatedWordsIndex[word.toString().toLowerCase()] = word;
        });

        let splitInput = splitTextToParts(inputString);

        let replacedInputArr = [];
        splitInput.forEach((inputWord) => {
          if (inputWord === ' ') {
            replacedInputArr.push("&nbsp;");

            return;
          }

          let lowerCaseWord = inputWord.toLowerCase();

          if (duplicatedWordsIndex[lowerCaseWord]) {
            this.noHighlight
                ? replacedInputArr.push(inputWord)
                : replacedInputArr.push(`<span class="highlight">${inputWord}</span>`);
          } else {
            replacedInputArr.push(inputWord);
          }
        });

        return replacedInputArr.join('');
      } else {
        return inputString;
      }
    },
    turnOnEditMode() {
      this.$emit('turn-on-edit-mode');
    },
    tooltipClickedOutside(e) {
    }
  },
  watch: {
    viewModePlaceholder(val) {
    },
    tableMode(newVal, oldVal) {
      if (newVal === 'view') {
        if (this.initialData !== '') {
          this.inputModel = this.initialData;
        }

        if (this.initialData === null && this.initialListData?.length > 0) {
          this.inputArray = [...this.initialListData];
          this.inputModel = this.initialListData.join(',');
        }
      }
    },
    initialData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {

        // if (newVal !== '') {
          // this.inputModel = this.initialData?.substring(0, this.textMaxLength);
          this.inputModel = newVal?.substring(0, this.textMaxLength);
        // }

        if (newVal === null && newVal?.length > 0) {
          this.inputArray = [...newVal];
          newVal = [...newVal];
          const nonEmptyItem = newVal.filter(item => item && item !== '');
          this.inputModel = nonEmptyItem.join(',');
        }
      }
    },
    initialListData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        newVal = [...newVal];
        this.inputModel = newVal.join(',').replace(/,+/g, ',');
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>