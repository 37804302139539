<template>
  <div class="table-wrap competitors-tab">
    <div class="search-and-filter-block"
         v-show="filtersLoaded">
      <div class="filters-options-block">
        <div v-if="showFilters" class="filters-block">
          <div class="filter-item-wrap filters-trigger">
            <div class="search-locales-block">

              <custom-select :options="countriesList"
                             :initial-data="'App store'"
                             :close-dropdown="closeSavedMetaDropdown"
                             :classes="'flat outlined'">

                <template v-slot:heading>
                  {{ currentLocale?.localeName }}
                </template>

                <template v-slot:results>
                  <div class="results-block">
                    <template v-if="countriesList?.length > 0">
                      <div class="results-item"
                           @click="localeItemClicked(localeItem)"
                           v-for="localeItem in countriesList">
                        <div class="item-inner">
                          <div class="meta-name">{{ localeItem?.localeName }}</div>
                        </div>
                      </div>
                    </template>
                    <div class="no-results" v-else>No locales found</div>
                  </div>
                </template>
              </custom-select>
            </div>
          </div>

        </div>

        <div v-if="showSearch" class="search-keywords-block">
          <basic-search-input placeholder="Search by keywords"
                              :hide-results-block="true"
                              @search-input-changed="searchKeywordsHandler"
                              :clear-input="searchKeywordsInput">

            <template v-slot:search-icon>
              <template v-if="!showKeywordsLoader">
                <svg-icon v-if="searchKeywordsInput?.length === 0"
                          class="search-icon"
                          icon="zoom-icon-new"/>

                <svg-icon v-else
                          @click="clearKeywordsSearchInput"
                          class="close-icon"
                          icon="close"/>
              </template>
              <div class="preloader-wrap-small"
                   v-else>
                <preloader-table-small loader-size="20px"></preloader-table-small>
              </div>
            </template>
          </basic-search-input>
        </div>
      </div>
    </div>

    <template v-if="keywordsLoaded">
      <div class="words-table-competitor-wrap"
           v-if="wordsTableData?.length > 0">
        <div class="words-table competitors">
          <data-table :headers="headers"
                      :items="paginatedTrackedKeywords"
                      :pagination="tablePagination"
                      :sorting-meta="sortingMeta"
                      :items-per-page="+tablePagination.per_page"
                      :rows-per-page-items="[50, 100, 250, 500, 1000]"
                      :fixed-width="true"
                      :show-actions="false"
                      :sorting-keys="{direction: 'order', sorting: 'sort_by', paginate: 'per_page'}"
                      :no-pagination="tableOptions?.noPagination"
                      :show-per-page-selector="false"
                      :table-classes="'words-custom-table'"
                      :scroll-after-click="false"
                      @update-sort="updateSort">

            <template v-slot:items="{item}">
              <td v-for="headerItem in headers"
                  :style="{width: headerItem.width}"
                  :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem?.headingClass]">
                <div class="inner-content pos-relative">

                  <template v-for="(tdItem, tdIndex) in item"
                            :key="tdIndex">

                    <template v-if="tdIndex === headerItem.value">
                      <template v-if="headerItem?.value === 'logo'">

                        <img width="30"
                             height="30"
                             :src="cropImg(item.logo, '30x30', 'APP_STORE')"
                        />
                      </template>

                      <template v-if="headerItem?.value === 'title'">
                        <editable-table-cell :table-mode="'view'"
                                             :initial-data="tdItem"
                                             :store-data="null"
                                             :duplicated-words="null"
                                             :text-max-length="30"
                                             view-mode-placeholder="No title"
                                             :no-initial-trim="true"
                                             edit-mode-placeholder="Enter the title">
                        </editable-table-cell>
                      </template>

                      <template v-else-if="headerItem?.value === 'sub_title'">
                        <editable-table-cell :table-mode="'view'"
                                             :initial-data="tdItem"
                                             :store-data="null"
                                             :duplicated-words="null"
                                             :text-max-length="30"
                                             view-mode-placeholder="No title"
                                             :no-initial-trim="true"
                                             edit-mode-placeholder="Enter the title">
                        </editable-table-cell>
                      </template>

                      <template v-else-if="headerItem?.value === 'installs'">
                        <div :class="{'blurred': hideInstalls}">{{ tdItem }}</div>
                      </template>

                      <template v-else-if="headerItem?.value === 'revenue'">
                        <div :class="{'blurred': hideInstalls}">{{ tdItem }}</div>
                      </template>
                    </template>
                  </template>

                </div>

              </td>
            </template>
          </data-table>
          <div class="subscription-block" v-if="hideInstalls">
            <svg-icon icon="locker"/>
            <div class="title">
              More with<br>Pro Plan
            </div>
            <base-button height="40px"
                         width="140px"
                         font-size="16px"
                         border-radius="4px"
                         :mode="'btn-standard'"
                         @click="openPlansModal">
              Go to Plans
            </base-button>
          </div>
        </div>
      </div>

      <div class="empty-block"
           v-else>
        No Data
      </div>
    </template>
    <template v-else>
      <div class="preloader-wrap">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </template>

  </div>
</template>

<script>
import DataTable from "@/components/DataTables/DataTable/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import {competitorsMetaTableConfig} from "@/configs/page-tables-configs/competitors-meta-table";
import {AppMetaCompetitorsArray} from "@/utils/factories";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import CustomRangeInput from "@/components/Forms/CustomRangeInput/index.vue";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import CustomRadioInput from "@/views/SearchTerms/RecommendedKeywords/components/CustomRadioInput/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TitleDescriptionDonut from "@/components/UI/TitleDescriptionDonut/index.vue";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import LiveSearchModal from "@/components/Modals/LiveSearchModal/index.vue";
import CustomChips from "@/components/Forms/CustomChips/index.vue";
import {cropImage} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import EditableTableCell from "@/views/MetaEditor/components/EditableTableCell/index.vue";
import CustomSelect from "@/components/Forms/CustomSelect/index.vue";

export default {
  name: "CompetitorsMeta",
  components: {
    EditableTableCell,
    SvgIcon,
    DataTable,
    'basic-search-input': BasicSearchInput,
    'data-table-checkbox': TableCheckbox,
    'range-input': CustomRangeInput,
    'radio-input': CustomRadioInput,
    'filters-dropdown': FiltersDropdown,
    'custom-dropdown': CustomDropdown,
    'title-description-chart': TitleDescriptionDonut,
    'sap-chart-modal': SapChartModal,
    'custom-chips': CustomChips,
    LiveSearchModal,
    'custom-select': CustomSelect,
  },
  emits: ['save-table-words', 'keyword-deleted', 'scroll-to-anchor'],
  props: {
    countryCode: {
      type: String
    },
    currentCountryCode: {
      type: String
    },
    currentCountryName: {
      type: String
    },
    currentCountryId: {
      type: Number
    },
    currentRedactionId: {
      type: String
    },
    currentFilterReset: {
      type: Number,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    searchKeywordValue: {
      type: String,
      default: null,
    },
    tableHeaders: {
      type: Object,
      default: null,
    },
    tableOptions: {
      type: Object,
      default: () => {
        return {
          noPagination: false,
        }
      }
    },
    countriesList: {
      type: Array,
    },
    editableMetas: {
      type: Object,
    },
    tableMode: {
      type: String,
    }
  },
  data() {
    return {
      headers: competitorsMetaTableConfig.headers,
      filtersReset: true,
      keywordsLoaded: false,
      filtersLoaded: false,
      showKeywordsLoader: false,
      wordsTableData: {},
      tablePagination: {
        current_page: 1,
        from: 1,
        to: 20,
        per_page: 50,
        last_page: 1,
        total: null,
        links: [
          {
            active: false,
            label: "&laquo; Previous",
          },
          {
            active: true,
            label: "1",
          },
          {
            active: false,
            label: "Next &raquo;",
          }
        ],
        skip: 0,
      },
      searchKeywordsInput: '',
      filtersInitObject: {},
      filtersDefaultObject: {
        filter: {},
        country_code: null,
        sap: {
          min: null,
          max: null,
          filter: null,
        },
        impressions: {
          min: null,
          max: null
        },
        rank: {
          min: null,
          max: null
        },
      },
      closeAllDropdownsFlag: 0,
      showCopyActiveFlag: false,
      showDeclinedActiveFlag: false,
      checkAllChanged: false,
      countryLocalesConfig: {
        border: 'none',
        borderRadius: '4px',
        boxShadow: '0px 4px 8px rgba(160, 190, 221, 0.3)',
        height: '36px',
      },
      currentLocale: {},
      closeSavedMetaDropdown: 0,
    }
  },
  methods: {
    insertSubscriptionBlock() {
      const tableHead = this.$el.querySelector('.advanced-custom-data-table thead');
      const lastColumnHeader = tableHead.querySelector('th:last-child');
      const subscriptionBlock = document.querySelector('.subscription-block');

      lastColumnHeader.appendChild(subscriptionBlock);
    },
    setDefaultTableData() {
      this.$store.dispatch('metaData/SET_SORTING_META', {
        sort_by: 'installs',
        order: 'desc',
        country_code: this.filtersInitObject?.country_code ?? this.currentKeywordCountryCode,
        'filter[like][query]': null,
      });
      this.filtersInitObject = {...this.filtersDefaultObject};
      this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', null);
    },
    async fetchWordsTableData(searchQuery) {
      this.keywordsLoaded = false;
      const countryCode = this.countryCode;
      const searchString = searchQuery || this.searchKeywordsInput;

      let url = `?app_id=${this.currentApp?.id}&country_code=${countryCode}&locale_code=${this.currentLocale?.localeCode ?? 'en'}`;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }
      if (searchString !== '') {
        url += '&filter[like][query]=' + searchString;
      }

      if (this.urlQueryString === '') {
        url += '&sort_by=popularity&order=desc';
      } else {
        url += this.urlQueryString;
      }

      const competitorsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_COMPETITORS + url);

      this.wordsTableData = AppMetaCompetitorsArray(competitorsData)?.map(item => {
        return {
          ...item,
          title: this.highlightduplicatedWords(item?.title),
          sub_title: this.highlightduplicatedWords(item?.sub_title),
        }
      });

      this.updatePagination({total: competitorsData?.length});
      this.keywordsLoaded = true;
    },
    async filterApplied(e, filter) {
      const keyMin = 'filter[' + [filter] + '][from]';
      const keyMax = 'filter[' + [filter] + '][to]';
      let filterObject = {
        [keyMin]: e?.min ?? null,
        [keyMax]: e?.max ?? null,
      };

      this.cleanUnusedFilters();
      this.updatePagination({page: 1});
      await this.updateSort(filterObject);
    },
    async updateSort(data, mode) {
      let innerData = {...data};

      this.$emit('scroll-to-anchor');
      if (data?.page || data?.per_page) {
        this.updatePagination(data);
      } else {

        if (this.searchKeywordValue) {
          innerData = {
            ...innerData,
            'filter[like][query]': this.searchKeywordValue
          }
        }

        this.$store.dispatch('metaData/SET_SORTING_META', innerData);
        this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', innerData);
        if (mode) {
          await this.fetchWordsTableData(mode);
        } else {
          await this.fetchWordsTableData();
        }
        this.closeAllDropdownsFlag++;
      }

      this.$emit('scroll-to-anchor');
    },
    async searchKeywordsHandler(inputValue) {
      this.tableIsLoading = true;
      this.showKeywordsLoader = true;
      this.searchKeywordsInput = inputValue;
      this.$store.dispatch('metaData/SET_SORTING_META', {'filter[like][query]': inputValue});
      this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', {'filter[like][query]': inputValue});
      await this.fetchWordsTableData(inputValue);
      this.$emit('scroll-to-anchor');
      setTimeout(() => {
        this.showKeywordsLoader = false;
        this.tableIsLoading = false;
      }, 300);
    },
    async clearKeywordsSearchInput() {
      await this.searchKeywordsHandler('');
    },
    async localeItemClicked(locale) {
      this.currentLocale = {...locale};
      this.closeSavedMetaDropdown++;
      this.updatePagination({page: 1});
      await this.fetchWordsTableData();
    },
    updatePagination(data) {
      const tablePagination = {...this.tablePagination};
      for (const key in data) {
        if (key === 'page') {
          tablePagination.current_page = data[key];
        } else if (key === 'per_page') {
          tablePagination[key] = data[key];
          tablePagination.current_page = 1;
        } else {
          tablePagination[key] = data[key];
        }
      }

      const from = (tablePagination?.per_page * +tablePagination?.current_page) - +tablePagination?.per_page;
      const to = +tablePagination?.current_page * +tablePagination?.per_page;
      tablePagination.from = from <= 0 ? 1 : from;
      tablePagination.to = to > tablePagination.total ? tablePagination.total : to;
      tablePagination.current_page = +tablePagination.current_page;

      let linksCount = +tablePagination.total / +tablePagination?.per_page;
      linksCount = Math?.ceil(linksCount);
      tablePagination.last_page = linksCount;

      const maxValue = 6;
      let links = [];

      if (tablePagination?.current_page <= 3 && linksCount >= maxValue) {
        for (let i = 1; i <= maxValue; i++) {
          links.push(
              {
                active: i === tablePagination?.current_page,
                label: i + '',
              }
          );
        }
      } else {
        links = [
          {
            active: true,
            label: tablePagination?.current_page + '',
          },
        ];

        if (tablePagination?.current_page - 1 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 1 + '',
          });
        }

        if (tablePagination?.current_page - 2 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 2 + '',
          });
        }

        if (tablePagination?.current_page + 1 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 1 + '',
          });
        }

        if (tablePagination?.current_page + 2 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 2 + '',
          });
        }
      }

      tablePagination.links = [...links];

      let leftArrowLink = {
        active: false,
        label: "&laquo; Previous",
      };

      let rightArrowLink = {
        active: false,
        label: "Next &raquo;",
      };

      tablePagination.links.unshift(leftArrowLink);
      tablePagination.links.push(rightArrowLink);

      this.tablePagination = {...tablePagination};
    },
    cleanUnusedFilters() {
      let localTableFilters = {...this.tableFilters};

      for (const key in localTableFilters) {
        if (localTableFilters[key].opened) {

          if (localTableFilters[key].value === "range_sap" && !this.filtersInitObject['filter[range_sap][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_impression" && !this.filtersInitObject['filter[range_impression][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_rank" && !this.filtersInitObject['filter[range_rank][from]']) {
            localTableFilters[key].opened = false;
          }

        }
      }

      this.tableFilters = {...localTableFilters};
    },
    detectTextOverflow(string) {
      if (!string?.length) {
        return;
      }

      let maxWidth;

      if (window.innerWidth > 1480) {
        maxWidth = window.innerWidth - 960;
        // maxWidth = '100%';
      } else if (window.innerWidth < 1481) {
        maxWidth = 270;
      }

      const defaultCharWidth = 10;
      const stringLength = defaultCharWidth * string?.length;

      return stringLength > maxWidth;
    },
    cropImg(imageUrl, size, store) {
      return cropImage(imageUrl, size, store);
    },
    highlightduplicatedWords(metaString) {
      if (this.searchKeywordsInput !== '') {
        const str = metaString.replace(new RegExp(this.searchKeywordsInput, "gi"), (match) => `<mark>${match}</mark>`);
        return str;
      }

      return metaString;
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  async mounted() {
    if (this.tableHeaders) {
      this.headers = [...this.tableHeaders?.headers];
    }

    if (this.countriesList?.length > 0) {
      this.currentLocale = {...this.countriesList[0]};
    }

    this.setDefaultTableData();
    await this.fetchWordsTableData(this.searchKeywordValue);
    this.filtersLoaded = true;
    this.insertSubscriptionBlock();
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userData',
      'sidebarIsCollapsed',
      'currentCountry',
      'currentCountryManual',
      'isTablet',
      'isMobile',
      'countryList',
      'userSubscription',
    ]),
    ...mapGetters('metaData', [
      'sortingMeta',
      'urlQueryString',
    ]),
    paginatedTrackedKeywords() {
      if (this.wordsTableData) {
        const splicedResult = [...this.wordsTableData];
        const from = this.tablePagination.from === 1 ? this.tablePagination.from - 1 : this.tablePagination.from;
        return splicedResult.slice(from, this.tablePagination.to);
      }

      return this.wordsTableData;
    },
    hideInstalls() {
      return this.userSubscription?.plan_name === 'Free';
    },
  },
  watch: {
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.updatePagination({page: 1});
        this.setDefaultTableData();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.updatePagination({page: 1});
        this.setDefaultTableData();
      }
    },
    currentFilterReset(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setDefaultTableData();
      }
    },
    async searchKeywordValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.dispatch('metaData/SET_SORTING_META', {'filter[like][query]': this.searchKeywordValue});
        this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', {'filter[like][query]': this.searchKeywordValue});

        await this.fetchWordsTableData(this.searchKeywordValue);
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>